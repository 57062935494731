﻿import React, { useState } from "react";
import PropTypes from "prop-types";

import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import classNames from "classnames";

import "./InlineFilterCell.css";

export default function InlineFilterCell(props) {
  const { column, type, handleFilterChange, leftOffset, topOffset, freezeheader, hidden } = props;

  const [text, setText] = useState("");
  const [filterType, setFilterType] = useState("contains");

  function handleTextChange(e) {
    setText(e.target.value);
    handleFilterChange(column, e.target.value, filterType);
  }

  function handleFilterTypeChange(type) {
    setFilterType(type);
    handleFilterChange(column, text, filterType);
  }
  let styles="";

  if(freezeheader===true)
  {
     styles =
     topOffset > 0
      ? {
          transform: `translate(0px,${topOffset -1}px)`,
          position: "relative",
          zIndex: 1,
        }
      : null;
  }
  else
  {
     styles =
    leftOffset > 0
      ? {
          transform: `translate(${leftOffset}px, 0px)`,
          position: "relative",
          zIndex: 1,
        }
      : null;
  }

  return (
    <div className="inline-filter-cell" style={styles} hidden={hidden}>
      <FilterTypeDropdown
        type={type}
        selectedFilterType={filterType}
        handleFilterTypeChange={handleFilterTypeChange}
      />
      <Form.Control
        //style={{ height: "40px" }}
        className="filter-text-input"
        value={text}
        type="text"
        onChange={handleTextChange}
        hidden={hidden}
      />
    </div>
  );
}

InlineFilterCell.propTypes = {
  column: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "number", "money", "percent", "bool"])
    .isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  leftOffset: PropTypes.number.isRequired,
};

function FilterTypeDropdown(props) {
  const { type, selectedFilterType, handleFilterTypeChange } = props;

  const filters = type === "text" ? ["contains"] : ["contains", ">", "<"];

  let popover = (
    <Popover id="popover-basic">
      <Popover.Content>
        <ul className="filter-type-list">
          {filters.map((filter, index) => {
            let className = classNames({
              "selected-filter-type": filter === selectedFilterType,
            });
            return (
              <li
                key={index}
                className={className}
                onClick={(event) =>
                  handleFilterTypeChange(event.target.textContent)
                }
              >
                {filter}
              </li>
            );
          })}
        </ul>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={popover}
      rootClose
    >
      <i className="filter-icon fa fa-filter" />
    </OverlayTrigger>
  );
}

FilterTypeDropdown.propTypes = {
  type: PropTypes.oneOf(["text", "number", "money", "percent", "bool"])
    .isRequired,
  selectedFilterType: PropTypes.string.isRequired,
  handleFilterTypeChange: PropTypes.func.isRequired,
};

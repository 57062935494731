﻿import React, { useMemo } from "react";
import classNames from "classnames";

import "./Cell.css";
import { Link, BrowserRouter as Router } from "react-router-dom";

import Collapsible from "react-collapsible";
import SubDataTable from "./DataTable";

export default function Cell(props) {
  const { type, leftOffset, showHyperlink, linkTo, hidden, ...other } = props;

  const styles =
    leftOffset > 0
      ? {
          transform: `translate(${leftOffset}px, 0px)`,
          position: "relative",
          zIndex: 1,
          backgroundColor: "#F5F5F5",
          hidden: { hidden },
        }
      : null;

  //if (showHyperlink) {
  //let styles = { textDecoration: "underline" };
  //}

  function getCell() {
    if (props.hidden) {
      return null;
    }

    switch (type) {
      case "money":
        return <MoneyCell styles={styles} {...other} />;
      case "number":
        return <NumberCell styles={styles} {...other} />;
      case "bool":
        return <BoolCell styles={styles} {...other} />;
      case "percent":
        return <PercentCell styles={styles} {...other} />;
      default:
        if (showHyperlink) {
          return <HyperlinkCell linkTo={linkTo} styles={styles} {...other} />;
        } else {
          return <TextCell styles={styles} {...other} />;
        }
    }
  }

  // Only rerendering on leftOffset change or content change.
  const cell = useMemo(() => {
    return getCell();
  }, [leftOffset, props.content]);

  return cell;
}

function TextCell(props) {
  const { content, styles, handleCellClick, hidden, subcolumns, row } = props;
  if (content === "Expand") {
    return (
      <div style={{ width: "130px" }}>
        <Collapsible trigger="Expand" triggerWhenOpen="Collapse">
          <SubDataTable
            columns={subcolumns}
            rows={row}
            id="nested-table"
            totalFooter
            filterable
            pagination
          />
        </Collapsible>
      </div>
    );
  } else {
    return (
      <div
        style={styles}
        className="cell"
        onClick={() => handleCellClick()}
        hidden={props.hidden}
      >
        {content === null ? "" : content}
      </div>
    );
  }
}

function HyperlinkCell(props) {
  const { content, styles, handleCellClick, linkTo, hidden } = props;

  return (
    <div
      style={styles}
      className="cell"
      onClick={() => handleCellClick()}
      hidden={props.hidden}
    >
      {content === null ? (
        ""
      ) : (
        <Router>
          <Link to={linkTo}>{content}</Link>
        </Router>
      )}
    </div>
  );
}

function MoneyCell(props) {
  const { content, styles, handleCellClick, hidden } = props;

  let num = parseFloat(content);
  if (isNaN(num)) num = content;

  let isNegative = content < 0;
  let className = classNames("cell", "money", { negative: isNegative });

  if (isNegative) {
    num = Math.abs(num);
  }

  // if (num === undefined || num === null) {
  //   num = 0;
  // }

  return num == null ? (
    <div
      style={styles}
      className={className}
      onClick={() => handleCellClick()}
      hidden={props.hidden}
    ></div>
  ) : (
    <div
      style={styles}
      className={className}
      onClick={() => handleCellClick()}
      hidden={props.hidden}
    >
      {num.toLocaleString(undefined, {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </div>
  );
}

function NumberCell(props) {
  const { content, styles, handleCellClick, alert, hidden } = props;

  let num = content;
  let isNegative = content < 0;
  let className = classNames(
    "cell",
    "number",
    { negative: isNegative },
    { "alert-cell": alert }
  );

  // if (isNegative) {
  //   num = Math.abs(num);
  // }

  // if (num === undefined || num === null) {
  //   num = 0;
  // }

  return num == null ? (
    <div
      style={styles}
      className={className}
      onClick={() => handleCellClick()}
      hidden={hidden}
    ></div>
  ) : (
    <div
      style={styles}
      className={className}
      onClick={() => handleCellClick()}
      hidden={hidden}
    >
      {num.toLocaleString(undefined)}
    </div>
  );
}

function BoolCell(props) {
  const { content, styles, handleCellClick, hidden } = props;

  return (
    <div
      style={styles}
      className="cell"
      onClick={() => handleCellClick()}
      hidden={hidden}
    >
      <i className={content ? "fa fa-check-circle" : "fa  fa-times-circle"} />
    </div>
  );
}

function PercentCell(props) {
  const { content, styles, handleCellClick, hidden } = props;

  let num = parseFloat(content);
  if (isNaN(num)) num = content;

  let isNegative = content < 0;
  let className = classNames("cell", "percent", { negative: isNegative });

  return num == null ? (
    <div
      style={styles}
      className={className}
      onClick={() => handleCellClick()}
    ></div>
  ) : (
    <div
      style={styles}
      className={className}
      onClick={() => handleCellClick()}
      hidden={hidden}
    >
      {num.toLocaleString(undefined, {
        style: "percent",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </div>
  );
}

﻿import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import "./TotalFooter.css";
export default function TotalFooter(props) {
  const { show, columns, rows, scrollOffset, subcolumns } = props;

  if (!show || columns.length === 0 || rows.length === 0) return null;

  let numberOfLeftFixedColumns = columns.filter(x => x.fixedLeft).length;
  return (
    <div className="total-footer">
      <div className="total-footer-row">
        {columns.map((column, index) => {
          if (column.hidden) {
            return null;
          }

          const styles =
            index < numberOfLeftFixedColumns && scrollOffset > 0
              ? {
                  transform: `translate(${scrollOffset}px, 0px)`,
                  position: "relative",
                  zIndex: 1,
                  backgroundColor: "#034681"
                }
              : null;

          if (index === 0) {
            if (subcolumns != undefined) {
              return (
                <div
                  style={styles}
                  className="total-footer-cell grand-total-footer-text"
                  key={index}
                >
                  Grand Total
                </div>
              );
            } else {
              return (
                <div
                  style={styles}
                  className="total-footer-cell grand-total-footer-text"
                  key={index}
                >
                  Total
                </div>
              );
            }
          } else if (column.showTotal) {
            const transformFn = column.transform;

            function mySum(total, currentRow) {
              const currentVal =
                typeof transformFn === "function"
                  ? transformFn(currentRow[column.accessor])
                  : currentRow[column.accessor];
              return total + currentVal;
            }

            //let sum = rows.reduce((a, b) => a + b[column.accessor], 0);
            let sum = rows.reduce(mySum, 0);

            let isNegative = sum < 0;

            let className = classNames(
              "total-footer-cell",
              { "money-cell": column.type === "money" },
              { "number-cell": column.type === "number" },
              { negative: isNegative }
            );

            let stringProps = {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            };

            let value =
              column.type === "money"
                ? sum.toLocaleString(undefined, stringProps)
                : sum.toLocaleString(undefined);

            return (
              <div style={styles} key={index} className={className}>
                {value}
              </div>
            );
          } else {
            return (
              <div className="total-footer-cell" style={styles} key={index} />
            );
          }
        })}
      </div>
    </div>
  );
}

const columnExact = {
  accessor: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "number", "money", "percent", "bool"])
    .isRequired,
  fixedLeft: PropTypes.bool,
  showTotal: PropTypes.bool.isRequired,
  hidden: PropTypes.bool,
  transform: PropTypes.func
};

TotalFooter.propTypes = {
  show: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(PropTypes.exact(columnExact)).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  scrollOffset: PropTypes.number,
  subcolumns: PropTypes.arrayOf(PropTypes.object)
};

﻿import React, { useState, useRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";

import HeaderCell from "./HeaderCell";

import "./HeaderRow.css";

export default function HeaderRow(props) {
  const { columns, handleColumnSort, scrollOffset, scrollYOffset, freezeheader } = props;

  const [isSorted, setIsSorted] = useState(false);
  const [isDesc, setIsDesc] = useState(false);
  const [sortedColumn, setSortedColumn] = useState("");

  function handleCellClick(column, type) {
    if (handleColumnSort === undefined) {
      if (sortedColumn === column) {
        if (isDesc) {
          setIsDesc(false);
          setIsSorted(false);
          //handleColumnSort(column, false, false, type);
        } else if (isSorted) {
          setIsDesc(true);
          //handleColumnSort(column, isSorted, true, type);
        } else {
          setIsSorted(true);
          //handleColumnSort(column, true, false, type);
        }
      } else {
        setSortedColumn(column);
        setIsSorted(true);
        setIsDesc(false);
        //handleColumnSort(column, true, false, type);
      }
    } else {
      if (sortedColumn === column) {
        if (isDesc) {
          setIsDesc(false);
          setIsSorted(false);
          handleColumnSort(column, false, false, type);
        } else if (isSorted) {
          setIsDesc(true);
          handleColumnSort(column, isSorted, true, type);
        } else {
          setIsSorted(true);
          handleColumnSort(column, true, false, type);
        }
      } else {
        setSortedColumn(column);
        setIsSorted(true);
        setIsDesc(false);
        handleColumnSort(column, true, false, type);
      }
    }
  }


  
if(freezeheader===true)
{
  return (
    <div className="header-row">
      {columns.map((column) => {
        return (
          <HeaderCell
            key={column.accessor}
            accessor={column.accessor}
            title={column.title}
            type={column.type}
            sortedColumn={sortedColumn}
            sorted={sortedColumn === column.accessor ? isSorted : false}
            desc={sortedColumn === column.accessor ? isDesc : false}
            handleCellClick={handleCellClick}
            topOffset={scrollYOffset}
            headerInfo={column.headerInfo}
            hidden={column.hidden}
            freezeheader={freezeheader}
          />
        );
      })}
    </div>
  );
}
else
{
  return (
    <div className="header-row">
      {columns.map((column) => {
        return (
          <HeaderCell
            key={column.accessor}
            accessor={column.accessor}
            title={column.title}
            type={column.type}
            sortedColumn={sortedColumn}
            sorted={sortedColumn === column.accessor ? isSorted : false}
            desc={sortedColumn === column.accessor ? isDesc : false}
            handleCellClick={handleCellClick}
            leftOffset={column.fixedLeft ? scrollOffset : 0}
            headerInfo={column.headerInfo}
            hidden={column.hidden}
          />
        );
      })}
    </div>
  );
}
}



const columnExact = {
  accessor: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "number", "money", "percent", "bool"])
    .isRequired,
  title: PropTypes.string.isRequired,
  fixedLeft: PropTypes.bool.isRequired,
  headerInfo: PropTypes.string,
  hidden: PropTypes.bool
};

HeaderRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.exact(columnExact)).isRequired,
  scrollOffset: PropTypes.number,
  handleColumnSort: PropTypes.func,
  hidden: PropTypes.bool
};

﻿import React from "react";
import PropTypes from "prop-types";
import Cell from "./Cell";

import "./Row.css";

export default function Row(props) {
  const {
    columns,
    row,
    rowIndex,
    scrollOffset,
    handleCellClick,
    hyperlinkColumnIndex,
    linkTo,
    subcolumns
  } = props;

  if (row.Record != undefined) {
    return (
      <React.Fragment>
        <div className="data-table-row">
          {columns.map((column, index) => {
            if (index == hyperlinkColumnIndex) {
              return (
                <Cell
                  key={rowIndex + "-" + index}
                  type={column.type}
                  content={row[column.accessor]}
                  leftOffset={column.fixedLeft ? scrollOffset : 0}
                  handleCellClick={() => handleCellClick(column.accessor)}
                  showHyperlink
                  linkTo={linkTo}
                  alert={
                    column.isCellAlert
                      ? column.isCellAlert(row, column.accessor)
                      : false
                  }
                  hidden={column.hidden}
                  subcolumns={subcolumns}
                  row={row.Record}
                />
              );
            } else {
              const transformFn = column.transform;
              //const sortValueFn = column.sortValue;
              //console.log(column);
              return (
                <Cell
                  key={rowIndex + "-" + index}
                  type={column.type}
                  content={
                    typeof transformFn === "function"
                      ? column.transform(row[column.accessor], index, row)
                      : "ERR: Transform"
                  }
                  leftOffset={column.fixedLeft ? scrollOffset : 0}
                  handleCellClick={() => handleCellClick(column.accessor)}
                  alert={
                    column.isCellAlert
                      ? column.isCellAlert(row, column.accessor)
                      : false
                  }
                  hidden={column.hidden}
                  subcolumns={subcolumns}
                  row={row.Record}
                />
              );
            }
          })}
        </div>
        {/* <div className="data-table-row">
          <Collapsible trigger="+">
            <SubDataTable
              columns={subcolumns}
              rows={row.Record}
              id="nested-table"
              totalFooter
              filterable
            />
          </Collapsible>
        </div> */}
      </React.Fragment>
    );
  } else {
    return (
      <div className="data-table-row">
        {columns.map((column, index) => {
          if (index == hyperlinkColumnIndex) {
            return (
              <Cell
                key={rowIndex + "-" + index}
                type={column.type}
                content={row[column.accessor]}
                leftOffset={column.fixedLeft ? scrollOffset : 0}
                handleCellClick={() => handleCellClick(column.accessor)}
                showHyperlink
                linkTo={linkTo}
                alert={
                  column.isCellAlert
                    ? column.isCellAlert(row, column.accessor)
                    : false
                }
                hidden={column.hidden}
              />
            );
          } else {
            const transformFn = column.transform;
            //const sortValueFn = column.sortValue;
            //console.log(column);
            return (
              <Cell
                key={rowIndex + "-" + index}
                type={column.type}
                content={
                  typeof transformFn === "function"
                    ? column.transform(row[column.accessor], index, row)
                    : "ERR: Transform"
                }
                leftOffset={column.fixedLeft ? scrollOffset : 0}
                handleCellClick={() => handleCellClick(column.accessor)}
                alert={
                  column.isCellAlert
                    ? column.isCellAlert(row, column.accessor)
                    : false
                }
                hidden={column.hidden}
              />
            );
          }
        })}
      </div>
    );
  }
}
const columnExact = {
  accessor: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "number", "money", "percent", "bool"])
    .isRequired,
  fixedLeft: PropTypes.bool,
  isCellAlert: PropTypes.func,
  hidden: PropTypes.bool,
  transform: PropTypes.func,
  filterValue: PropTypes.func
};

Row.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.exact(columnExact)).isRequired,
  row: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  scrollOffset: PropTypes.number.isRequired,
  handleCellClick: PropTypes.func.isRequired,
  showHyperlink: PropTypes.bool,
  hyperlinkColumnIndex: PropTypes.number,
  hidden: PropTypes.bool
};

﻿import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import Row from "./lib/Grid/Row";
import ActionBar from "./lib/Grid/ActionBar";
import DataTableHead from "./lib/Grid/DataTableHead";
import DataTableSorter from "./lib/Grid/DataTableSorter";
import TotalFooter from "./lib/Grid/TotalFooter";
import Paging from "./lib/Grid/Paging";

export function ContentHeader(props) {
  return <h2 className="content-header">{props.name}</h2>;
}

export function DataTable(props) {
  const {
    columns,
    rows,
    exportable = false,
    filterable = false,
    totalFooter = false,
    pagination = false,
    id,
    handleCellClick,
    hyperlinkColumnIndex,
    linkTo,
    subcolumns,
    stopheaderColumnSort = false,
    expandAll = false,
    exportcolumns,
    exportrows,
    freezeheader = false
  } = props;

  const [sorted, setSorted] = useState(false);
  const [sortedColumn, setSortedColumn] = useState({});
  const [filtered, setFiltered] = useState(false);
  const [viewableRows, setViewableRows] = useState([]);
  const [pageOfData, setPageOfData] = useState([]);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [scrollYOffset, setScrollYOffset] = useState(0);
  const [headerColumnData, setheaderColumnData] = useState([]);
  const [filterColumnData, setFilterColumnData] = useState([]);
  const [rowColumnData, setRowColumnData] = useState([]);
  const [totalFooterColumnData, setTotalFooterColumnData] = useState([]);
  const [actionBarColumnData, setActionBarColumnData] = useState([]);

  useEffect(() => {
    if (columns.length > 0) {
      let normalizedColumns = columns.map((col) => {
        return {
          accessor: col.accessor,
          title: col.title,
          type: col.type || "text",
          showTotal: col.showTotal || false,
          fixedLeft: col.fixedLeft || false,
          headerInfo: col.headerInfo,
          isCellAlert: col.isCellAlert,
          hidden: col.hidden || false,
          transform:
            col.transform ||
            function (value, idx, row) {
              return value;
            },
        };
      });

      setActionBarColumnData(getActionBarColumnData(normalizedColumns));
      setheaderColumnData(getHeaderColumnData(normalizedColumns));
      setFilterColumnData(getFilterColumnData(normalizedColumns));
      setRowColumnData(getRowColumnData(normalizedColumns));
      setTotalFooterColumnData(getTotalFooterColumnData(normalizedColumns));
    }
  }, [columns]);

  function getActionBarColumnData(columns) {
    return columns.map((x) => ({
      accessor: x.accessor,
      type: x.type,
      title: x.title,
    }));
  }

  function getHeaderColumnData(columns) {
    return columns.map((x) => ({
      accessor: x.accessor,
      type: x.type,
      title: x.title,
      fixedLeft: x.fixedLeft,
      headerInfo: x.headerInfo,
      hidden: x.hidden,
    }));
  }

  function getFilterColumnData(columns) {
    return columns.map((x) => ({
      accessor: x.accessor,
      type: x.type,
      fixedLeft: x.fixedLeft,
      hidden: x.hidden,
    }));
  }

  function getRowColumnData(columns) {
    return columns.map((x) => ({
      accessor: x.accessor,
      type: x.type,
      fixedLeft: x.fixedLeft,
      isCellAlert: x.isCellAlert,
      hidden: x.hidden,
      transform: x.transform,
    }));
  }

  function getTotalFooterColumnData(columns) {
    return columns.map((x) => ({
      accessor: x.accessor,
      type: x.type,
      fixedLeft: x.fixedLeft,
      showTotal: x.showTotal,
      hidden: x.hidden,
    }));
  }

  function handleColumnSort(column, isSorted, isDesc, type) {
    if (isSorted) {
      setSorted(isSorted);
      // Get sorter based on column type
      let sorter = DataTableSorter(type);

      // If table is already filtered we sort only filtered rows
      let data = filtered ? viewableRows.slice() : rows.slice();
      setViewableRows(
        data.sort(
          isDesc ? sorter.sortDescending(column) : sorter.sortAscending(column)
        )
      );
    } else if (sorted) {
      setSorted(false);
    }

    // We set sorted column so we can sort on filter changes
    setSortedColumn({
      column: column,
      isSorted: isSorted,
      isDesc: isDesc,
      type: type,
    });
  }

  function handleFilterChange(filters) {
    // Check if any filters exist and set state
    let anyFilters = false;
    for (let key in filters) {
      if (filters[key].text !== "") {
        anyFilters = true;
        break;
      }
    }
    setFiltered(anyFilters);

    let filteredRows = anyFilters ? filterRows(filters) : rows;

    // if data was previously sorted, we sort the new filtered data
    if (sorted) {
      const { column, type, isDesc } = sortedColumn;
      let sorter = DataTableSorter(type);
      setViewableRows(
        filteredRows.sort(
          isDesc ? sorter.sortDescending(column) : sorter.sortAscending(column)
        )
      );
    } else {
      setViewableRows(filteredRows);
    }
  }

  function filterRows(filters) {
    return rows.slice().filter((row) => {
      for (var key in filters) {
        //const filterValue = columns.filter(c => c.Name === key).SortValue(row[key]);

        let filter = filters[key];
        let cellValue = row[key];
        const matchColumn = (column) =>
          column.accessor == key && column.filterValue;
        if (columns.some(matchColumn)) {
          const columnIndex = columns.findIndex(matchColumn);
          const filterValue = columns
            .find(matchColumn)
            .filterValue(cellValue, columnIndex, row);
          cellValue = filterValue;
        }

        let columnType = typeof cellValue;

        // return false if cell value is null
        if (cellValue == null) {
          return false;
        }

        // We can immediately return false if one of the columns are filtered out
        if (
          columnType === "string" &&
          !cellValue.toLowerCase().includes(filter.text)
        ) {
          return false;
        } else if (columnType === "number") {
          if (
            filter.type === "contains" &&
            !cellValue.toString().includes(filter.text)
          ) {
            return false;
          } else {
            let num = Number(filter.text);
            if (filter.type === ">" && row[key] <= num) {
              return false;
            } else if (filter.type === "<" && cellValue >= num) {
              return false;
            }
          }
        }
      }
      return true;
    });
  }

  function handleScroll() {
    let tableWrapper = document.getElementById(`${id}-data-table-wrapper`);
    setScrollOffset(tableWrapper.scrollLeft);
  }

  function handleYScroll() {
    let tableWrapper = document.getElementById(`${id}-data-table-wrapper`);
    setScrollYOffset(tableWrapper.scrollTop -15);

    // alert(scrollYOffset);
  }
  // function toggleCollapsible() {
  //   var elements = document.getElementsByClassName("Collapsible__trigger");
  //   var arrEl = Array.prototype.slice.call(elements);
  //   var anyExpanded = arrEl.some((e) => e.classList.contains("is-open"));

  //   if (anyExpanded) {
  //     arrEl
  //       .filter((e) => e.classList.contains("is-open"))
  //       .forEach((e) => e.click());
  //   } else {
  //     arrEl
  //       .filter((e) => e.classList.contains("is-closed"))
  //       .forEach((e) => e.click());
  //   }
  // }
  function collapse(){
    var elements = document.getElementsByClassName("Collapsible__trigger");
    var arrEl = Array.prototype.slice.call(elements);
    arrEl
        .filter((e) => e.classList.contains("is-open"))
        .forEach((e) => e.click());
  }

  function expand(){
    var elements = document.getElementsByClassName("Collapsible__trigger");
    var arrEl = Array.prototype.slice.call(elements);
    arrEl
        .filter((e) => e.classList.contains("is-closed"))
        .forEach((e) => e.click());
  }
  
  let data = sorted || filtered ? viewableRows : rows;

  let actionBarColumns =
    exportcolumns != null ? exportcolumns : actionBarColumnData;
  let actionBarData = exportrows != null ? exportrows : data;

  if (freezeheader===true)
  {
    let style = null;
    if (stopheaderColumnSort===false)
    {
      //"overflow-x: auto; width: 99%; overflow-y: scroll; height: 400px;";
       style=
       {
        // "overflow-x": "auto",
        width: "100%",
        padding:"10px",
        overflowY: "auto",
        maxHeight : "700px"
       }
    }
    else
    {
      //style="overflow-x: auto; width: 99%;";
      style=
      {
      //  "overflow-x": "auto",
       padding:"10px",
       width: "100%",
      }
    }
  
    let tableStyle = {}
    
    if( !subcolumns && columns.length > 7 ){
      tableStyle.display = "block";
    }
  return (
    <React.Fragment>
      {
        <ActionBar
          exportable={exportable}
          columns={actionBarColumns}
          rows={actionBarData}
          expandAll={stopheaderColumnSort && expandAll}
          // expandToggle={toggleCollapsible}
          collapse={collapse}
          expand={expand}
        />
      }
      <div
        className="data-table-wrapper"
        id={`${id}-data-table-wrapper`}
        onScroll={handleYScroll}
        style={style}
      >
        <div className="data-table" id={`${id}-data-table`} style={tableStyle}>
          {stopheaderColumnSort ? (
            <DataTableHead
              filterable={filterable}
              headerColumnData={headerColumnData}
              filterColumnData={filterColumnData}
              tableYOffset={scrollYOffset}
              //handleColumnSort={handleColumnSort}
              handleFilterChange={handleFilterChange}
              freezeheader={freezeheader}
            />
          ) : (
            <DataTableHead
              filterable={filterable}
              headerColumnData={headerColumnData}
              filterColumnData={filterColumnData}
              tableYOffset={scrollYOffset}
              handleColumnSort={handleColumnSort}
              handleFilterChange={handleFilterChange}
              freezeheader={freezeheader}
            />
          )}
          <div className="data-table-body">
            {pageOfData.map((row, index) => {
              return (
                <Row
                  key={index}
                  rowIndex={index}
                  handleCellClick={(col) => handleCellClick(row, col)}
                  scrollOffset={scrollOffset}
                  row={row}
                  columns={rowColumnData}
                  subcolumns={subcolumns}
                  hyperlinkColumnIndex={hyperlinkColumnIndex}
                  linkTo={linkTo}
                />
              );
            })}
          </div>
          <TotalFooter
            show={totalFooter}
            columns={totalFooterColumnData}
            subcolumns={subcolumns}
            rows={data}
            scrollOffset={scrollOffset}
          />
        </div>
        <Paging
          show={pagination}
          rows={data}
          onPageChange={(pageOfData) => setPageOfData(pageOfData)}
        />
      </div>
    </React.Fragment>
  );
}
else
{
  return (
    <React.Fragment>
      {
        <ActionBar
          exportable={exportable}
          columns={actionBarColumns}
          rows={actionBarData}
          expandAll={stopheaderColumnSort && expandAll}
          // expandToggle={toggleCollapsible}
          collapse={collapse}
          expand={expand}
        />
      }
      <div
        className="data-table-wrapper"
        id={`${id}-data-table-wrapper`}
        onScroll={handleScroll}
      >
        <div className="data-table" id={`${id}-data-table`}>
          {stopheaderColumnSort ? (
            <DataTableHead
              filterable={filterable}
              headerColumnData={headerColumnData}
              filterColumnData={filterColumnData}
              tableXOffset={scrollOffset}
              //handleColumnSort={handleColumnSort}
              handleFilterChange={handleFilterChange}
            />
          ) : (
            <DataTableHead
              filterable={filterable}
              headerColumnData={headerColumnData}
              filterColumnData={filterColumnData}
              tableXOffset={scrollOffset}
              handleColumnSort={handleColumnSort}
              handleFilterChange={handleFilterChange}
            />
          )}
          <div className="data-table-body">
            {pageOfData.map((row, index) => {
              return (
                <Row
                  key={index}
                  rowIndex={index}
                  handleCellClick={(col) => handleCellClick(row, col)}
                  scrollOffset={scrollOffset}
                  row={row}
                  columns={rowColumnData}
                  subcolumns={subcolumns}
                  hyperlinkColumnIndex={hyperlinkColumnIndex}
                  linkTo={linkTo}
                />
              );
            })}
          </div>
          <TotalFooter
            show={totalFooter}
            columns={totalFooterColumnData}
            subcolumns={subcolumns}
            rows={data}
            scrollOffset={scrollOffset}
          />
        </div>
        <Paging
          show={pagination}
          rows={data}
          onPageChange={(pageOfData) => setPageOfData(pageOfData)}
        />
      </div>
    </React.Fragment>
  );
}
}

DataTable.defaultProps = {
  columns: [],
  rows: [],
};
const columnExact = {
  accessor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "number", "money", "percent", "bool"]),
  showTotal: PropTypes.bool,
  fixedLeft: PropTypes.bool,
  headerInfo: PropTypes.string,
  transform: PropTypes.func,
  filterValue: PropTypes.func,
  hidden: PropTypes.bool,
};

DataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.exact(columnExact)).isRequired,
  subcolumns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  exportable: PropTypes.bool,
  filterable: PropTypes.bool,
  totalFooter: PropTypes.bool,
  pagination: PropTypes.bool,
  hyperlinkColumnIndex: PropTypes.number,
  linkTo: PropTypes.string,
  stopheaderColumnSort: PropTypes.bool,
  exportcolumns: PropTypes.arrayOf(PropTypes.object),
  exportrows: PropTypes.arrayOf(PropTypes.object),  
  expandAll: PropTypes.bool,
};
﻿import React, { useState, useEffect, useRef, useCallback } from "react";

import HeaderRow from "./HeaderRow";
import InlineFilterRow from "./InlineFilterRow";

export default function DataTableHead(props) {
  const {
    filterable,
    tableXOffset,
    tableYOffset,
    headerColumnData,
    filterColumnData,
    handleColumnSort,
    handleFilterChange,
    freezeheader
  } = props;

  if (freezeheader===true)
  {
    if (handleColumnSort === false) {
      return (
        <div className="data-table-head">
          <HeaderRow scrollYOffset={tableYOffset} columns={headerColumnData} freezeheader={freezeheader} />
          <InlineFilterRow
            scrollYOffset={tableYOffset}
            columns={filterColumnData}
            show={filterable}
            handleFilterChange={handleFilterChange}
            freezeheader={freezeheader}
          />
        </div>
      );
    } else {
      return (
        <div className="data-table-head">
          <HeaderRow
            scrollYOffset={tableYOffset}
            columns={headerColumnData}
            handleColumnSort={handleColumnSort}
            freezeheader={freezeheader}
          />
          <InlineFilterRow
            scrollYOffset={tableYOffset}
            columns={filterColumnData}
            show={filterable}
            handleFilterChange={handleFilterChange}
            freezeheader={freezeheader}
          />
        </div>
      );
    }
  }
  else
  {
    if (handleColumnSort === false) {
      return (
        <div className="data-table-head">
          <HeaderRow scrollOffset={tableXOffset} columns={headerColumnData} freezeheader={freezeheader}/>
          <InlineFilterRow
            scrollOffset={tableXOffset}
            columns={filterColumnData}
            show={filterable}
            handleFilterChange={handleFilterChange}
          />
        </div>
      );
    } else {
      return (
        <div className="data-table-head">
          <HeaderRow
            scrollOffset={tableXOffset}
            columns={headerColumnData}
            handleColumnSort={handleColumnSort}
          />
          <InlineFilterRow
            scrollOffset={tableXOffset}
            columns={filterColumnData}
            show={filterable}
            handleFilterChange={handleFilterChange}
          />
        </div>
      );
    }
  }
}

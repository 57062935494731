﻿import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormControl
} from "react-bootstrap";

import "./Paging.css";

export default function Pagination(props) {
  const { rows, show } = props;

  const [pageSize, setPageSize] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const perPageOptions = [10, 20, 30, 40];

  useEffect(() => {
    if (rows.length === 0) {
      props.onPageChange([]);
    }
    setPage(selectedPage);
  }, [rows, pageSize]);

  function handlePageSizeChange(event) {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  }

  function handlePageSelectorChange(event) {
    setPage(parseInt(event.target.value, 10));
  }

  function setPage(page) {
    let totalRows = rows.length;
    if (totalRows > 0) {
      let totalPages = Math.ceil(totalRows / pageSize);

      if (page > totalPages) {
        page = totalPages;
      } else if (page < 1) {
        page = 1;
      }

      let startIndex = (page - 1) * pageSize;
      let endIndex = Math.min(startIndex + pageSize - 1, totalRows - 1);
      let pageOfRows = rows.slice(startIndex, endIndex + 1);

      setSelectedPage(page);
      setStartIndex(startIndex);
      setEndIndex(endIndex);
      setTotalPages(totalPages);
      props.onPageChange(pageOfRows);
    }
  }

  if (!show || rows.length === 0) return null;

  let pages = [];
  for (var i = 1; i <= totalPages; i++) pages.push(i);

  return (
    <Row className="no-gutters mt-2 ml-2 paginationFooter">
      <Col className="align-self-center ">
        Showing {endIndex + 1 - startIndex} of {rows.length} Records
      </Col>
      <Col className="col-md-auto">
        <FormControl
          className="page-selector"
          value={pageSize}
          onChange={handlePageSizeChange}
          as="select"
        >
          {perPageOptions.map((option, index) => {
            return <option key={index}>{option}</option>;
          })}
        </FormControl>{" "}
        Rows Per Page
      </Col>
      <Col as={ButtonToolbar} className="justify-content-end">
        <ButtonGroup>
          <Button variant="link" onClick={() => setPage(1)}>
            <i className="paginationControlButton fa-angle-double-left" />
          </Button>
          <Button variant="link" onClick={() => setPage(selectedPage - 1)}>
            <i className="paginationControlButton fa-angle-left" />
          </Button>
          <span>
            {" "}
            Page{" "}
            <FormControl
              className="page-selector"
              value={selectedPage}
              onChange={handlePageSelectorChange}
              as="select"
            >
              {pages.map(num => {
                return <option key={num}>{num}</option>;
              })}
            </FormControl>{" "}
            of {totalPages}
          </span>
          <ButtonGroup>
            <Button variant="link" onClick={() => setPage(selectedPage + 1)}>
              <i className="paginationControlButton fa-angle-right" />
            </Button>
            <Button variant="link" onClick={() => setPage(totalPages)}>
              <i className="paginationControlButton fa-angle-double-right" />
            </Button>
          </ButtonGroup>
        </ButtonGroup>
      </Col>
    </Row>
  );
}

Pagination.propTypes = {
  show: PropTypes.bool.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  onPageChange: PropTypes.func.isRequired
};

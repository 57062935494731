﻿import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Nav, NavDropdown, NavItem } from "react-bootstrap";
import ReactExport from "react-export-excel";
import ReactCSVExport from "react-csv-downloader";

import "./ActionBar.css";


export default function ActionBar(props) {
  const { exportable, rows, columns, expandAll, collapse, expand } = props;

  if (!exportable && !expandAll) {
    return null;
  }

  return (
    <React.Fragment>
      <Nav variant="pills">
        <ExcelExport show={exportable} rows={rows} columns={columns} />
        <ExpandCollapseAll show={expandAll} collapse={collapse} expand={expand}  />
      </Nav>
    </React.Fragment>
  );
}

const columnExact = {
  accessor: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "number", "money", "percent", "bool"]),
  title: PropTypes.string.isRequired,
  transform: PropTypes.func,
};

ActionBar.propTypes = {
  exportable: PropTypes.bool.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.exact(columnExact)),
  transform: PropTypes.func,
  expandAll: PropTypes.bool,
  // expandToggle: PropTypes.func,
  expand: PropTypes.func,
  collapse: PropTypes.func,
};

const ExpandCollapseAll = (props) => {
  const { show, 
    // expandToggle, 
    collapse,
    expand
  } = props;

  const [expanded, setExpanded] = useState(false)

  

  const expandAll = ()=>{
    setExpanded(true);
    expand();
  }

  const collapseAll = ()=>{
    setExpanded(false);
    collapse();
  }

  return show ? (
    <NavItem>
      {/* <button className="btn btn-primary" onClick={expandToggle}>
        Expand/Collapse All
      </button> */}
      <button onClick={expandAll} className={expanded ? "action-link grayed" : "action-link"   }><i className="fa fa-expand"></i> Expand All</button>
      <button onClick={collapseAll} className={expanded ?  "action-link" : "action-link grayed" }><i className="fa fa-compress"></i> Collapse All</button>
    </NavItem>
  ) : null;
};

function ExcelExport(props) {
  const { show, rows, columns } = props;

  if (!show || rows.length === 0 || columns.length === 0) return null;

  function getExcelData() {
    let xlsxColumns = [];
    columns.forEach((column) => {
      xlsxColumns.push(column.title);
    });

    let xlsxRows = [];
    rows.forEach((row) => {
      let xlsxRow = [];
      columns.forEach((column, index) => {
        if (row[column.accessor]) {
          const transformFn = column.transform;
          const cellValue =
            typeof transformFn === "function"
              ? column.transform(row[column.accessor], index, row)
              : row[column.accessor];

          let xlsxColumn = {};
          switch (column.type) {
            case "money":
              let moneyFormat = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });

              if (cellValue < 0) {
                xlsxColumn = {
                  value: "(" + moneyFormat.format(-cellValue) + ")",
                  style: { font: { color: { rgb: "red" } } },
                };
              } else {
                xlsxColumn.value = moneyFormat.format(cellValue);
              }
              break;
            case "percent":
              let percentFormat = new Intl.NumberFormat("en-US", {
                style: "percent",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });

              if (cellValue < 0) {
                xlsxColumn.style = { font: { color: { rgb: "red" } } };
              }

              xlsxColumn.value = percentFormat.format(cellValue);
              break;
            default:
              xlsxColumn.value = cellValue.toString();
              break;
          }
          xlsxRow.push(xlsxColumn);
        } else {
          xlsxRow.push({ value: "", style: { font: { color: {} } } });
        }
      });
      xlsxRows.push(xlsxRow);
    });

    return [
      {
        columns: xlsxColumns,
        data: xlsxRows,
      },
    ];
  }

  const exportButton = useMemo(() => {
    return (
      <NavDropdown
        title={
          <i className="fa fa-download" aria-hidden>
            {" "}
            Download .xlsx{" "}
          </i>
        }
        id="nav-dropdown"
      >
        <ReactExport.ExcelFile
          fileName="xlsxExport"
          element={
            <NavDropdown.Item>
              <i className="fa fa-file-excel-o" aria-hidden>
                Excel Spreadsheet .xlsx
              </i>
            </NavDropdown.Item>
          }
        >
          <ReactExport.ExcelFile.ExcelSheet
            dataSet={getExcelData()}
            name="xlsxExport"
          />
        </ReactExport.ExcelFile>
      </NavDropdown>
    );
  }, [rows, columns]);

  return <React.Fragment>{exportButton}</React.Fragment>;
}

ExcelExport.propTypes = {
  show: PropTypes.bool.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.exact(columnExact)),
};

﻿import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import classNames from "classnames";
import "./HeaderCell.css";

export default function HeaderCell(props) {
  const {
    title,
    accessor,
    sorted,
    desc,
    type,
    leftOffset,
    topOffset, 
    handleCellClick,
    headerInfo,
    hidden,
    freezeheader
  } = props;

  let styles ="";

  if(freezeheader===true)
  {
  // for frozen header
   styles =
  topOffset > 0
      ? {
          transform: `translate(0px, ${topOffset}px)`,
          position: "relative",
          zIndex: 1,
        }
      : null; 
  }
  else
  {
  // for frozen columns
   styles =
    leftOffset > 0
      ? {
          transform: `translate(${leftOffset}px,0px)`,
          position: "relative",
          zIndex: 1,
        }
      : null;
  }

  function handleHeaderCellClick(e) {
    if (e.target.className !== "header-info-popover-icon fa fa-info-circle") {
      handleCellClick(accessor, type);
    }
  }
  let className = classNames(
    "header-cell",
    { text: type === "cell" },
    { number: type === "number" },
    { money: type === "money" }
  );

  return (
    <div
      style={styles}
      className={className}
      onClick={handleHeaderCellClick}
      hidden={hidden}
    >
      {title}
      <SortIcon sorted={sorted} desc={desc} />
      <InfoIcon headerInfo={headerInfo} />
    </div>
  );
}

HeaderCell.propTypes = {
  title: PropTypes.string.isRequired,
  accessor: PropTypes.string.isRequired,
  sorted: PropTypes.bool.isRequired,
  desc: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["text", "number", "money", "percent", "bool"])
    .isRequired,
  leftOffset: PropTypes.number,
  handleCellClick: PropTypes.func.isRequired,
  headerInfo: PropTypes.string,
  hidden: PropTypes.bool
};

function SortIcon(props) {
  const { sorted, desc } = props;

  let className = sorted
    ? desc
      ? " fa fa-sort-down"
      : " fa fa-sort-up"
    : " fa fa-sort";
  return <i className={"sort-icon" + className} />;
}

SortIcon.propTypes = {
  sorted: PropTypes.bool.isRequired,
  desc: PropTypes.bool.isRequired
};

function InfoIcon(props) {
  const { headerInfo } = props;

  if (headerInfo === undefined) return null;

  let overlay = (
    <Popover id="popover-basic">
      <Popover.Content>{headerInfo}</Popover.Content>
    </Popover>
  );
  return (
    <span>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={overlay}
        rootClose
      >
        <i className="header-info-popover-icon fa fa-info-circle" />
      </OverlayTrigger>
    </span>
  );
}

InfoIcon.propTypes = {
  headerInfo: PropTypes.string
};

﻿import React, { useState } from "react";
import PropTypes from "prop-types";
import InlineFilterCell from "./InlineFilterCell";

import "./InlineFilterRow.css";
import Cell from "./Cell";

export default function InlineFilterRow(props) {
  const { show, columns, scrollOffset, scrollYOffset, freezeheader } = props;

  const [filters, setFilters] = useState({});

  function handleFilterChange(column, text, type) {
    let newFilters = filters;

    if (text === "") {
      delete newFilters[column];
    } else {
      newFilters[column] = {
        text: text.toLowerCase(),
        type: type,
        column: column
      };
    }

    setFilters(newFilters);
    props.handleFilterChange(newFilters);
  }

  if (!show) return null;

  if(freezeheader===true)
  {
    return (
      <div className="inline-filter-row">
        {columns.map((column) => {
          return (
            <InlineFilterCell
              key={column.accessor}
              column={column.accessor}
              type={column.type}
              handleFilterChange={handleFilterChange}
              topOffset={scrollYOffset}
              hidden={column.hidden}
              freezeheader={freezeheader}
            />
          );
        })}
      </div>
    );
  }
else
{
  return (
    <div className="inline-filter-row">
      {columns.map((column) => {
        return (
          <InlineFilterCell
            key={column.accessor}
            column={column.accessor}
            type={column.type}
            handleFilterChange={handleFilterChange}
            leftOffset={column.fixedLeft ? scrollOffset : 0}
            hidden={column.hidden}
          />
        );
      })}
    </div>
  );
}
}

const columnExact = {
  accessor: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "number", "money", "percent", "bool"])
    .isRequired,
  fixedLeft: PropTypes.bool.isRequired,
  hidden: PropTypes.bool.isRequired
};

InlineFilterRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.exact(columnExact)).isRequired,
  show: PropTypes.bool.isRequired,
  scrollOffset: PropTypes.number
};
